
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import HorizontalRule from '~/components/_general/HorizontalRule.vue'
import { Broadcaster } from '~/models/broadcaster'
import {
  BroadcasterDonationMethod,
  BroadcasterDonationOption,
} from '~/apiclient/apibroadcasters'
import BroadcasterPhone from '~/components/broadcaster/Phone.vue'
import { displayUrl } from '~/assets/ts/utils/misc'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import BroadcasterActionModal from '~/components/broadcaster/ActionModal.vue'

export default Vue.extend({
  name: 'BroadcasterDonateSelections',
  components: {
    BroadcasterActionModal,
    InlineIcon,
    BroadcasterPhone,
    HorizontalRule,
  },
  props: {
    broadcaster: {
      type: Object as PropType<Broadcaster>,
      default: undefined,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    overrideDonationOptions: {
      type: Array as PropType<BroadcasterDonationOption[]>,
      default: undefined,
    },
  },
  computed: {
    address(): string {
      if (!this.getMethod(BroadcasterDonationMethod.Mail)) return ''
      return this.broadcaster?.donationAddress ?? ''
    },
    options(): BroadcasterDonationOption[] {
      if (this.overrideDonationOptions) return this.overrideDonationOptions
      return this.broadcaster?.donationOptions ?? []
    },
    buttonOptions(): BroadcasterDonationOption[] {
      const exclude = [
        BroadcasterDonationMethod.Stripe,
        BroadcasterDonationMethod.Mail,
      ]
      return this.options.filter((o) => !exclude.includes(o.method))
    },
  },
  methods: {
    getMethod(
      method: BroadcasterDonationMethod
    ): BroadcasterDonationOption | undefined {
      return this.options.find((o) => o.method === method)
    },
    getLabel(option: BroadcasterDonationOption): TranslateResult {
      switch (option.method) {
        case BroadcasterDonationMethod.Cornerstone:
          return `${this.$t('Credit Card')} / ACH`
        case BroadcasterDonationMethod.Paypal:
          return this.$t('PayPal')
        case BroadcasterDonationMethod.Stripe:
        case BroadcasterDonationMethod.Mail:
        case BroadcasterDonationMethod.Other:
        default:
          return option.label || option.method
      }
    },
    getDisplayUrl(option: BroadcasterDonationOption) {
      return displayUrl(this.getUrl(option), true)
    },
    getUrl(option: BroadcasterDonationOption) {
      if (option.url) return option.url
      if (option.method === BroadcasterDonationMethod.Paypal) {
        // https://developer.paypal.com/api/nvp-soap/paypal-payments-standard/integration-guide/html-example-donate/#link-urlcodefordonateemailpaymentlinks
        let url = `https://www.paypal.com/donate?business=${option.email}&amount=25&currency_code=USD&no_recurring=0`
        if (option.designation) {
          url += `&item_name=${option.designation}`
        }
        return url
      }
      console.error(`No available url found for ${option.method}`, option)
      return ''
    },
  },
})
